import * as R from 'ramda'
import React from 'react'
import { TextField, Typography, Grid, Switch } from '@material-ui/core'
import Calendar from 'react-calendar'
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { notNilOrEmpty } from '../lib/Helpers'

export default ({
  exhibitorEmail,
  exhibitorContact,
  token,
  setNoCaptchaToken
}) => {
  const [time, setTime] = React.useState(new Date())
  const [date, setDate] = React.useState('')
  const [timezone, setTimezone] = React.useState(false)

  const [form, setForm] = React.useState({
    date: date,
    time: time,
    timezone: timezone,
    email: '',
    name: ''
  })

  React.useEffect(() => {
    setForm({
      ...form,
      date: date && format(date, 'EEEE LLL do yyyy'),
      time: time && format(time, 'HH:mm'),
      timezone: timezone ? 'EDT' : 'PDT'
    })
  }, [time, date, timezone])

  function _handleFormData(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  function _handleForm(e) {
    e.preventDefault()

    notNilOrEmpty(token)
      ? fetch('/api/meeting', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            date: form.date,
            email: form.email,
            name: form.name,
            time: form.time,
            timezone: form.timezone,
            exhibitorEmail: exhibitorEmail,
            exhibitorContact: exhibitorContact
          })
        }).then(res => {
          if (res.status >= 400) {
            return Swal.fire({
              icon: 'error',
              title: 'Oops... Something went wrong!',
              text: 'There has been an error. Please try again.',
              customClass: {
                confirmButton: 'btn btn--black btn--confirm'
              },
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            })
          } else {
            setForm({
              date: '',
              email: '',
              name: '',
              time: '',
              timezone: ''
            })

            return Swal.fire({
              icon: 'success',
              title: 'Thank you.',
              text: 'We are sending your meeting request.',
              customClass: {
                confirmButton: 'btn btn--black btn--confirm'
              },
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            })
          }
        })
      : setNoCaptchaToken('Could not verify Captcha Token')
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form
          method="post"
          className="exhibitor-meeting-form"
          action="/"
          onSubmit={_handleForm}
        >
          <Typography variant="body1">
            Pick a day and time that works for you and it will be emailed to the
            team who will then respond
          </Typography>
          <TextField
            className="input"
            type="text"
            name="name"
            id="name"
            label="Your Full Name"
            onChange={_handleFormData}
            value={form.name}
            required
            helperText="3 Characters Minimum"
          />
          <TextField
            className="input"
            type="email"
            name="email"
            id="email"
            label="Your email"
            onChange={_handleFormData}
            value={form.email}
            required
          />
          <div>
            <TimePicker
              ampm={false}
              margin="normal"
              id="time"
              name="time"
              label="Pick a Time"
              value={time}
              onChange={setTime}
              // KeyboardButtonProps={{
              //   'aria-label': 'change time'
              // }}
            />
            <Typography component="div" className="text--center">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                style={{ justifyContent: 'center' }}
              >
                <Grid item>PDT</Grid>
                <Grid item>
                  <Switch
                    checked={timezone}
                    onChange={e => setTimezone(e.target.checked)}
                    name="timezone"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Grid>
                <Grid item>EDT</Grid>
              </Grid>
            </Typography>
          </div>
          <div>
            <p>Pick a date: </p>
            <Calendar onChange={setDate} value={date} />
          </div>
          <div className="submit-button">
            <button
              className="btn btn--black btn-confirm"
              type="submit"
              disabled={R.not(
                notNilOrEmpty(token) &&
                  notNilOrEmpty(form.name) &&
                  R.gt(form.name.length, 3) &&
                  notNilOrEmpty(form.email) &&
                  notNilOrEmpty(form.date) &&
                  notNilOrEmpty(form.time)
              )}
            >
              Send
            </button>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  )
}
